<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>菜单权限</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div></div>
            <div class="_add">
                <button @click="openDialog('add')">添加角色</button>
            </div>
        </div>
        <div class="page_content">
            <el-table :data="tableData" border>
                <el-table-column prop="id" align="center" label="id"> </el-table-column>
                <el-table-column prop="title" align="center" label="名字"> </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="_operate">
                            <button @click="openDialog('set', row)">编辑</button>
                        </div>
                        <div class="_delete">
                            <button @click="del(row)">删除</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 添加或编辑弹出框 -->
        <el-dialog
            :title="type == 'add' ? '添加角色' : type == 'set' ? '编辑角色' : ''"
            :visible.sync="dialogShow"
            width="70%"
            custom-class="edit"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="角色名">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="权限等级">
                    <el-select v-model="form.level" placeholder="请选择">
                        <el-option :value="1" label="全部权限"> </el-option>
                        <el-option :value="2" label="仅限查看"> </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="设置权限">
                    <!-- 穿梭框 -->
                    <el-transfer
                        filterable
                        filter-placeholder="搜索"
                        v-model="form.user_menu_id"
                        :data="bkList"
                        :titles="['未选择权限', '已选择权限']"
                    >
                    </el-transfer>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取消</el-button>
                <el-button type="primary" @click="yes">提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
    data() {
        return {
            tableData: [],
            bkList: [],
            form: {
                title: "",
                user_menu_id: [],
            },
            dialogShow: false,
            // add添加 set编辑
            type: "",
        };
    },
    async created() {
        this.tableData = await this.getData_js();
        this.bkList = (await this.getData_bk()).map(e => {
            return {
                key: e.id,
                label: e.title,
            };
        });
    },
    methods: {
        // 获取所有板块(权限)
        async getData_bk() {
            let { data } = await axios.get("/api/user_role_menu/mshow");
            return data;
        },
        // 获取所有角色
        async getData_js() {
            let { data } = await axios.get("/api/user_role_menu/rshow");
            return data;
        },
        // 打开添加或编辑弹出框
        openDialog(type = "", row) {
            this.type = type;
            this.dialogShow = true;
            if (type == "add") {
                this.form = { title: "", user_menu_id: [] };
            } else if (type == "set") {
                this.form = {
                    id: row.id,
                    title: row.title,
                    level: row.level,
                    user_menu_id: row.menu.map(e => {
                        return e.id;
                    }),
                };
            }
        },
        // 提交
        async yes() {
            if (this.type == "add") {
                // 添加
                var { data } = await axios.post("/api/user_role_menu/radd", qs.stringify(this.form));
            } else if (this.type == "set") {
                // 编辑
                var { data } = await axios.put("/api/user_role_menu/rupdate", qs.stringify(this.form));
            }

            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
                this.tableData = await this.getData_js();
            }
            this.dialogShow = false;
        },
        // 删除
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/user_role_menu/roledel", { params: { id } });
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
                this.tableData = await this.getData_js();
            }
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_headerSecondary {
        display: flex;
        align-items: center;
        margin-top: 20px;
        & > div {
            flex: 1;
        }
        ._add {
            text-align: right;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
}
</style>
